<template>
    <node-view-wrapper
        as="div"
        class="resizable-node"
        :class="{
            'readonly': !editor?.isEditable,
            '!max-w-full': node.attrs.fullWidth,
            '!mx-24': node.attrs.fullWidth && editor.isEditable,
        }"
    >
        <Toast/>

        <Dialog
            v-if="showDialog"
            v-model:visible="showDialog"
            modal
            :header="`Select template`"
            :style="{ width: '80%' }"
            @update:visible="onUpdateDialog"
        >
            <TemplateSelection @insertContent="insertContent" />
        </Dialog>
        <CardError v-if="!loading && error"/>
    </node-view-wrapper>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { NodeViewWrapper } from '@tiptap/vue-3';
import axios from 'axios';
import { useRoomPage } from '../../../../pages/RoomDetail/hooks/useRoomPage.js';
import { displayErrorMessagesAsToast } from '../../../../utils/errorUtils.js';
import useValidatedForm from '../../../../hooks/useValidatedFormNew';
import * as yup from 'yup';
import CardError from '../../../components/ui/CardError.vue';
import TemplateSelection from '@/pages/Templates/components/TemplateSelection.vue';


const showDialog = ref(false);
const error = ref(false);
const props = defineProps({
    editor: Object,
    node: Object,
    editable: Boolean,
    form: Object,
    onSubmit: Function,
    updateAttributes: {
        type: Function,
        required: true,
    },
})

const {
    editable,
} = useRoomPage();

const currentContact = ref(null);
const loading = ref(true);

const handleEditNode = (event) => {
    const {type, id} = event.detail;

    if (props.node.attrs.id === id && props.node.type.name === type) {
        openDialog();
    }
};

onMounted(() => {
    window.addEventListener('edit-node', handleEditNode);
})

onUnmounted(() => {
    window.removeEventListener('edit-node', handleEditNode);
})

const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Email is not valid').notRequired(),
});

onMounted(() => {
    if (props.node.attrs.id) {
        getContact(props.node.attrs.id)
    } else {
        showDialog.value = true;
    }
});

function onUpdateDialog() {
    if (props.node && props.editor) {
        const { editor, getPos, node } = props;

        const from = getPos()
        const to = from + node.nodeSize

        editor.view.dispatch(
            editor.state.tr.deleteRange(from, to)
        );
    }
}

function insertContent(content) {
    if (props.editor) {
        const { editor } = props;
        editor.chain().focus().insertContent(JSON.parse(content)).run();
    }
}

async function getContact(id) {
    try {
        const {data} = await axios.get('/contacts/' + id)
        currentContact.value = data
        form.vee.setValues(data);
    } catch (e) {
        error.value = true;
        console.error(e)
    } finally {
        loading.value = false
    }
}

function openDialog() {
    if (!editable) {
        return;
    }

    showDialog.value = true;
}

async function onSubmit() {
    return new Promise((resolve, reject) => {
        if (!editable) {
            resolve();
            return;
        }

        const formData = form.data();

        const transformedData = {
            ...formData,
            _method: 'put',
            avatar: formData.avatar instanceof File ? formData.avatar : undefined,
        };

        const requestData = new FormData();
        Object.keys(transformedData).forEach(key => {
            requestData.append(key, transformedData[key]);
        });

        axios({
            method: 'post',
            url: `/contacts/${currentContact.value.id}`,
            data: transformedData,
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then(response => {
            const contactData = response.data.contact || {};
            currentContact.value = {
                ...currentContact.value,
                ...contactData
            };
            props.updateAttributes({
                id: currentContact.value.id,
                data: JSON.stringify(currentContact.value)
            });
            showDialog.value = false;
        }).catch(error => {
            showDialog.value = false;
            displayErrorMessagesAsToast(error);
        });
    })
}

</script>
<style lang="scss">
</style>
