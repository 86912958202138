import axios from 'axios';

const axiosInstance = axios.create();
console.log('SETT');
axiosInstance.interceptors.request.use(
    config => {
        console.log({
            config
        })
        return config;
    },
    error => {
        // Handle request errors
        return Promise.reject(error);
    }
);

export default axiosInstance;
