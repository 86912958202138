<template>
    <Dialog
        v-model:visible="isVisible"
        @hide="onHide"
        modal
        :style="{ width: 'calc(100% - 40px)'}"
    >
        <template #header>
            <div class="w-full flex justify-between items-center">
                <div>Template Preview - {{template?.name}}</div>
                <div class="ml-auto flex gap-2 mr-2">
                    <Button buttonSize="small" variant="secondary" @click="starTemplate" class="h-[28px]"
                        :title="isStarred ? 'Unstar this template' : 'Star this template '"
                    >
                        <StarIcon class="h-4" :fill="isStarred ? 'black' : 'none'"/>
                    </Button>
                    <Button buttonSize="small" variant="secondary" @click="createRoom" class="h-[28px]" title="Create room" :loading="loadingCreate">
                        <Icon v-if="!loadingCreate" name="Plus" class="h-4" />
                        Create room
                    </Button>
                </div>
            </div>
        </template>
        <div class="flex flex-col items-center justify-center h-full">
            <div class="flex items">
                <BlockEditor
                    :content="template.content"
                    :read-only="readOnly"
                />
            </div>
        </div>
    </Dialog>
</template>
<script setup lang="ts">
import Dialog from "primevue/dialog";
import { router, useForm } from '@inertiajs/vue3';
import { ref, watch, watchEffect } from "vue";
import Button from '@/tiptap/components/ui/button/Button.vue';
import BlockEditor from "@//tiptap/components/BlockEditor/BlockEditor.vue";
import Icon from '@/tiptap/components/ui/Icon.vue';
import { useToast } from 'primevue/usetoast';
import axios from "axios";
import { useAuth } from "@/hooks/useAuth";
import StarIcon from "@/pages/Templates/components/StarIcon.vue";
const { user } = useAuth();
const emit = defineEmits(['hide'])
const props = defineProps({
    visible: Boolean,
    template: Object,
    hide: {
        type: Function,
        required: true
    }
})

const toast = useToast();
const isStarred = ref(false);


const form = useForm({});

watch(() => props.template, (value) => {
    if (!value) return;

    isStarred.value = value.starred_by.find((u) => {
        return u.id === user.id;
    });
}, {immediate: true, deep: true})

const isVisible = ref(props.visible);
const readOnly = ref(true);
const loadingCreate = ref(false);
watch(() => props.visible, (value) => {
    isVisible.value = value;
})

function onHide(){
    emit('hide')
}


function createRoom(){
    loadingCreate.value = true;
    form
        .transform((data) => {
            return {
                name: 'Untitled',
                template_ulid: props.template.ulid,
                template_type: 'blank',
            }
        })
        .post('/rooms', {
            onSuccess: (data) => {
                const id = data.props.flash.room.ulid
                const pageId = data.props.flash.room?.pages?.[0]?.ulid;

                if (pageId) {
                    router.visit(`/room-detail/${id}/${pageId}`);
                }
            },
            onError: (err) => {
                displayErrorMessagesAsToast(err, toast)
            },
            onFinish: () => {
                loadingCreate.value = false;
            }
        });
}

async function starTemplate() {
    await axios.post(`/templates/${props.template.ulid}/star`);
    router.reload();

    isStarred.value = !isStarred.value;
}

</script>
