import { router, usePage } from '@inertiajs/vue3';
import * as FileUtils from '../utils/fileUtils.js';
import { displayErrorMessagesAsToast } from '../utils/errorUtils.js';
import { useToast } from 'primevue/usetoast';
import { useRoomPage } from '@/pages/RoomDetail/hooks/useRoomPage.js';


export function useBlockImage() {
    const page = usePage();
    const toast = useToast();
    const {template, room} = useRoomPage();

    function getUrl() {
        return template ? `/templates/${template.ulid}/library` : `/rooms/${room.ulid}/library`;
    }

    function uploadImage(file, model, callback, errorCallback) {

        const url = model ? getUrl() : '/library';
        console.log('URL FINAL', url)
        const formData = new FormData();
        formData.append('image', file); // Assuming 'file' is a File object

        axios.post(url, formData)
            .then(response => {
                const baseUrl = page.props.tenantBaseUrl;
                const media = response.data.media;

                const url = FileUtils.createFileURL(baseUrl, {
                    uuid: media.uuid,
                });

                callback(url, response);
                return url;
            })
            .catch(error => {
                displayErrorMessagesAsToast(error, toast);
                if (errorCallback) {
                    errorCallback(error); // Ensure errorCallback is defined to handle errors
                }
            });
    }

    return {uploadImage}
}
