<template>
    <div class="flex items-center justify-between py-2 bg-blue-50 h-[55px] px-9">
        <div class="flex-1 flex">
            <div class="flex items-center space-x-2 ">
                <div>
                    <Button variant="secondary" button-size="iconSmall" @click="navigateBack">
                        <ChevronLeftIcon class="w-4 h-4"/>
                    </Button>
                </div>
                <div class="flex gap-2 items-center group">
                    <InputEdit
                        :editable="canEditProjectName"
                        :is-edit="isEdit"
                        :modelValue="roomName"
                        @update:modelValue="value => roomName = value"
                        @submit="onUpdateTemplateName"
                        class="flex-1 room-name max-w-[300px]"
                    />
                    <Button class="opacity-0 group-hover:opacity-100" @click="isEdit = true" variant="ghost" button-size="icon" size="icon" aria-label="Edit">
                        <Icon name="Pencil"></Icon>
                    </Button>
                </div>
            </div>

        </div>
        <div class="flex-1 flex justify-center">
            <div class="relative flex gap-2">
                <Link
                    :href="getEditorLink()"
                    type="button"
                    :class="{
                            [classes.default]: true,
                            [classes.active]: isUrl('room-detail'),
                            '!text-gray-400': !isUrl('room-detail')
                        }"
                >
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.7206 6.98689L9.51319 4.77943M3.16675 13.3333L5.03446 13.1258C5.26265 13.1004 5.37674 13.0878 5.48339 13.0532C5.578 13.0226 5.66804 12.9793 5.75106 12.9246C5.84464 12.8629 5.92581 12.7817 6.08816 12.6194L13.3762 5.33129C13.9858 4.72172 13.9858 3.73341 13.3762 3.12384C12.7667 2.51426 11.7784 2.51426 11.1688 3.12383L3.88071 10.4119C3.71836 10.5743 3.63719 10.6554 3.57548 10.749C3.52073 10.832 3.47746 10.9221 3.44683 11.0167C3.4123 11.1233 3.39963 11.2374 3.37427 11.4656L3.16675 13.3333Z"
                              :stroke="isUrl('room-detail') ? '#0A1017' : '#969EA8'"
                              stroke-width="1.33"
                              stroke-linecap="round" stroke-linejoin="round"
                        />
                    </svg>
                    <span>Editor</span>
                </Link>

            </div>
        </div>
        <div class="flex-1 ">
            <div class="flex justify-end">
                <div class="relative flex gap-2">
                    <button
                        @click="(e) => {
                            e.preventDefault();
                            emit('openSettings')
                        }"
                        type="button"
                        :class="{
                            [classes.default]: true,
                            [classes.active]: isUrl('room-detail'),
                            '!text-gray-400': !isUrl('room-detail')
                        }"
                    >
                        <Icon name="Settings" />
                        <span>Settings</span>
                    </button>

                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useAuth } from "../../../hooks/useAuth.js";
import { Link, router, useForm, usePage } from "@inertiajs/vue3";
import { computed, ref, watch } from "vue";
import { ChevronLeftIcon } from "lucide-vue-next";
import Button from "@/tiptap/components/ui/button/Button.vue";
import InputEdit from '@/Components/InputEdit.vue';
import { displayErrorMessagesAsToast } from '@/utils/errorUtils';
import Icon from '@/tiptap/components/ui/Icon.vue';
import { useToast } from "primevue/usetoast";
const toast = useToast();
import { useTemplate } from '@/pages/Templates/useTemplate.js';
const {isAdmin, user} = useAuth();

const emit = defineEmits(['save'])
const { template, templateName } = useTemplate();
const page = usePage();

const isEdit = ref(false);
const roomName = ref(template.value.name);

const roomNameForm = useForm({});

function onUpdateTemplateName() {
    isEdit.value = false;
    if (roomName.value.length === 0) {
        return;
    }

    roomNameForm
        .transform((data) => {
            return {
                name: roomName.value,
                template_type: 'blank'
            }
        })
        .post('/templates/' + page.props.template.ulid + '/change-name', {
            onSuccess: () => {

            },
            onError: (err) => {
                displayErrorMessagesAsToast(err, toast)
            },
        });
}

const classes = {
    active: 'bg-gray-200 rounded',
    icon: 'w-5 h-5 mr-2',
    default: 'text-gray-900 flex items-center relative py-1 px-2 text-sm font-medium whitespace-nowrap sm:w-auto sm:px-4 gap-0.5 h-[32px]',
}

function navigateBack() {
    router.visit('/templates')
}

function getEditorLink() {
    return `/templates/${template.value.ulid}`;
}

function isUrl(value) {
    return window?.location?.href.includes(value)
}
</script>
