import { displayErrorMessagesAsToast } from '../../../utils/errorUtils.js';
import { router, useForm, usePage } from '@inertiajs/vue3';
import { computed, ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import useValidatedForm from '@/hooks/useValidatedFormNew';
import * as yup from 'yup';

export function useRoomPage(roomParam) {
    const page =  usePage();
    const toast = useToast();
    const room = roomParam || page.props.room;
    const template = page.props.template;
    const model = room || template;
    const isTemplate = !!template;
    const pages = ref(room?.pages);
    const editable = ref(!!page.props.editable);
    const external = ref(page.props.external);
    const addPage = ref(false);
    const editPage = ref(false);
    const schema = yup.object().shape({
        name: yup.string().required('Please enter page name.'),
    });

    const addPageForm = useValidatedForm({
        name: '',
    }, schema)

    const editPageForm = useValidatedForm({
        name: '',
        ulid: '',
    }, schema)

    const editPageEmojiForm = useValidatedForm({
        emoji: '',
        ulid: '',
    }, schema)

    function onDelete(page) {
        const index = pages.value.findIndex(p => p.id === page.id);
        pages.value.splice(index, 1);
    }

    function onEditPage(currentPage) {
        editPage.value = true

        editPageForm.vee.setValues({
            name: currentPage.name,
            ulid: currentPage.ulid,
        });
    }

    function editPageSubmit() {
        editPageForm
            .transform((data) => {
                return {
                    name: data.name,
                }
            })
            .put(`/page/${editPageForm.ulid}`, {
            onSuccess: (data) => {
                pages.value = data.props.room.pages;
                editPage.value = false
            },
            onError: (err) => {
                displayErrorMessagesAsToast(err, toast)
            },
        });
    }


    const planItemBaseUrl = computed(() => {
        return template ? '/template-plan-item' : '/plan-item';
    });

    const softwareAdoptionItemBaseUrl = computed(() => {
        return template ? '/template-software-adoption-item' : '/software-adoption-item';
    });

    const uploadImageBaseUrl = computed(() => {
        return template ? `/templates/${template.value.ulid}/library` : `/rooms/${room.ulid}/library`;
    });

    function createNewPage() {
        addPageForm.post(`/room-detail/${page.props.room.ulid}/add-page`, {
            onSuccess: (data) => {
                addPageForm.reset();
                pages.value = data.props.room.pages;
                const newPageUlid = data.props.flash.page.ulid
                router.visit(`/room-detail/${page.props.room.ulid}/${newPageUlid}`);
            },
            onError: (err) => {
                displayErrorMessagesAsToast(err)
            },
        });
    }

    function onEditEmoji(page, emoji) {
        console.log({page, emoji})
        editPageEmojiForm
            .transform((data) => {
                return {
                    emoji: emoji,
                }
            })
            .post(`/page/${page.ulid}/emoji`, {
                onSuccess: (data) => {
                    pages.value = data.props.room.pages;
                    editPage.value = false
                },
                onError: (err) => {
                    displayErrorMessagesAsToast(err, toast)
                },
            });
    }

    return {
        page,
        pages,
        addPage,
        editPage,
        addPageForm,
        editPageForm,
        onDelete,
        onEditPage,
        editPageSubmit,
        createNewPage,
        editable,
        external,
        room,
        model,
        planItemBaseUrl,
        softwareAdoptionItemBaseUrl,
        isTemplate,
        uploadImageBaseUrl,
        template,
        onEditEmoji
    }
}
