<template>
    <Sidebar
        v-model:visible="todoDetail"
        header="Edit task"
        position="right"
        class="w-full md:w-44rem overflow-x-hidden"
        style="width: 500px;"
    >
        <BaseFormCard @submit="submit" :loading="form.processing" :form="form">
            <div class="task-modal flex flex-col gap-4 text-sm px-2">
                <FormField v-slot="{ componentField }" name="title">
                    <FormItem v-auto-animate class="col-span-6">
                        <FormLabel>Task name</FormLabel>
                        <FormControl>
                            <Input v-bind="componentField"/>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>

                <FormField v-slot="{ componentField }" name="status">
                    <FormItem v-auto-animate class="col-span-6">
                        <FormLabel>Status</FormLabel>
                        <FormControl>
                            <Select v-bind="componentField">
                                <FormControl>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select the status"/>
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent style="z-index: 9999">
                                    <SelectGroup>
                                        <SelectItem v-for="model in statuses" :value="model.value">
                                            <div class="flex items-center gap-2">
                                                <div class="w-4 h-4 rounded-full" :style="{'background-color': model.color}"/>
                                                <div class="text-sm">{{ model.name }}</div>
                                            </div>
                                        </SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>

                <FormField v-slot="{ componentField }" name="activity_type">
                    <FormItem v-auto-animate class="col-span-6">
                        <FormLabel>Current Activity</FormLabel>
                        <FormControl>
                            <Select v-bind="componentField">
                                <FormControl>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select the current activity"/>
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent style="z-index: 9999">
                                    <SelectGroup>
                                        <SelectItem v-for="model in activityTypeOptions" :value="model.value">
                                            <div class="text-sm">{{ model.label }}</div>
                                        </SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>

                <FormField v-slot="{ componentField }" name="hours">
                    <FormItem v-auto-animate class="col-span-6">
                        <FormLabel>Hours</FormLabel>
                        <FormControl>
                            <Input type="number" v-bind="componentField"/>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>

                <div>
                    <label class="text-gray-950">Start date</label>
                    <vue-tailwind-datepicker
                        v-model="startDate"
                        :formatter="dateFormatter"
                        as-single
                        input-classes="border rounded px-3 py-1.5 flex items-center"
                    />
                </div>

                <div class="text-gray-950">
                    <label>Due date</label>
                    <vue-tailwind-datepicker
                        v-model="dueDate"
                        :formatter="dateFormatter"
                        as-single
                        input-classes="border rounded px-3 py-1.5 flex items-center"
                    />
                </div>

                <MultiSelect
                    :options="model.members"
                    v-model="todoDetail.members"
                    label="Members"
                    optionLabel="name"
                    placeholder="Select Members"
                    id="members"
                    class="w-full md:max-w-full"
                >
                    <template #option="slotProps">
                        <div class="flex items-center gap-2">
                            <BaseAvatar
                                :src="slotProps.option.avatar"
                                :name="slotProps.option.name"
                                class="w-5 h-5 rounded-full"
                            />
                            <div>{{ slotProps.option.name }}</div>
                        </div>
                    </template>
                    <template #value="slotProps">
                        <div class="flex gap-2 items-center flex-wrap" :class="{
                        'min-h-[20px]': slotProps.value.length === 0
                    }">
                            <div class="flex items-center gap-2" v-for="value in slotProps.value">
                                <BaseAvatar
                                    :src="value.avatar"
                                    :name="value.name"
                                    class="w-5 h-5 rounded-full"
                                />
                                <div>{{ value.name }}</div>
                            </div>
                        </div>
                    </template>
                </MultiSelect>

                <div>
                    <PlanItemActions
                        :model="todoDetail"
                        @update="($event) => {
                            todoDetail = $event;
                        }"
                    />
                </div>

                <div>
                    <label class="text-gray-950">Description</label>

                    <div class="border p-3">
                        <BlockEditor
                            :content="todoDetail.description"
                            container-class="lg:min-w-[400px] !w-[400px] overflow-hidden min-h-[90px]"
                            :with-nav="false"
                            :on-update="(v) => {
                                descriptionBuffer = v;
                            }"
                            :hide-placeholder-menu="true"
                            class="p-0"
                        />
                    </div>
                </div>
            </div>

            <template #footer>
                <div class="flex gap-2 mt-auto px-2">
                    <Button class="w-full mt-4" size="small" variant="secondary"
                            @click="() => {
                        todoDetail = null;
                    }"
                    >
                        Cancel
                    </Button>
                    <Button
                        class="w-full mt-4" :loading="loading" size="small" type="submit"
                    >
                        Save
                    </Button>
                </div>
            </template>
        </BaseFormCard>

    </Sidebar>
</template>
<script setup lang="ts">
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/Components/ui/form'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/Components/ui/select'
import { Input } from '@/Components/ui/input';
import Button from '../../../components/ui/button/Button.vue';
import Sidebar from "primevue/sidebar";
import VueTailwindDatepicker from "vue-tailwind-datepicker";
import BlockEditor from "../../../components/BlockEditor/BlockEditor.vue";
import { ref, watch } from "vue";
import { useRoomPage } from '../../../../pages/RoomDetail/hooks/useRoomPage.js';
import { displayErrorMessagesAsToast } from "../../../../utils/errorUtils";
import BaseAvatar from "../../../../Components/forms/BaseAvatar.vue";
import MultiSelect from "../../../../Components/forms/Multiselect.vue";
import PlanItemActions from "./PlanItemActions.vue";
import { formatDateToISO, formatISOToDate } from '@/utils/formatDate.js';
import { useTaskActivityType } from "../../../../hooks/useTaskActivityType";
import { planItemStatuses } from "../planItemStatuses";
import BaseFormCard from "@/Components/forms/BaseFormCard.vue";
import useValidatedForm from "@/hooks/useValidatedFormNew";
import { useToast } from 'primevue/usetoast';
const toast = useToast();
const props = defineProps({
    todoDetail: {
        type: Object,
        default: null
    }
})
const {model, planItemBaseUrl} = useRoomPage();

const emit = defineEmits(['close']);
const loading = ref(false);
const todoDetail = ref(props.todoDetail);
const descriptionBuffer = ref(todoDetail.value.description);

const form = useValidatedForm(props.todoDetail, {})

todoDetail.value.members = todoDetail.value.members
    .map((todoMember) => {
        return model.members.find((member) => member.id === todoMember.id);
    }).filter(v => !!v);

watch(() => todoDetail.value, (newVal) => {
    if (newVal === null || newVal === false) {
        setTimeout(() => {
            emit('close');
        }, 300);
    }
});

const startDate = ref(formatISOToDate(props.todoDetail.start_date) || []);
const dueDate = ref(formatISOToDate(props.todoDetail.due_date) || []);
const dateFormatter = ref({
    date: 'MMM DD, YYYY',
    month: 'MMM',
})

const { activityTypeOptions } = useTaskActivityType();

const statuses = ref(planItemStatuses);

async function submit() {
    loading.value = true;
    const sd = Array.isArray(startDate.value) ? startDate.value[0] : startDate.value;
    const dd = Array.isArray(dueDate.value) ? dueDate.value[0] : dueDate.value;
    todoDetail.value.description = descriptionBuffer.value;
    const data = form.data();
    delete data['action'];
    delete data['action_name'];
    delete data['action_url'];

    try {
        const response = await axios.put(`${planItemBaseUrl.value}/${todoDetail.value.id}`, {
            ...data,
            description: descriptionBuffer.value,
            start_date: formatDateToISO(sd),
            due_date: formatDateToISO(dd),
            members: todoDetail.value.members.map(member => member.id),
        });

        // Assuming the response contains updated todo details or confirmation
        todoDetail.value = null;
    } catch (err) {
        console.error('OnSerr', err); // Log and handle errors
        displayErrorMessagesAsToast(err, toast);
    } finally {
        loading.value = false; // Ensure loading is set to false after operation completes
    }
}

</script>
