<template>
    <div id="room-view">
        <Toast />
        <div class="relative mx-auto bg-blue-50">
            <TemplateEditorNavbar @save="onSubmit" @openSettings="openSettings"/>

            <div class="flex flex-col lg:flex-row w-full justify-center border-t border-gray-200 bg-white" style="border-radius: 32px;">
                <!-- Main Content (Block Editor) -->
                <div class="flex justify-center">
                    <div class="w-full max-w-[calc(100%-200px)] lg:max-w-none">
                        <BlockEditor
                            :content="template.content"
                            :on-update-room="onUpdateTemplate"
                            :read-only="false"
                            :should-scroll="true"
                        />
                    </div>
                </div>
            </div>
            <TemplateSettings :visible="settingsPanel" @close="(e) => {
                console.log('CLose', {e, template})
                settingsPanel = false;
            }" />
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import BlockEditor from '@/tiptap/components/BlockEditor/BlockEditor.vue';
import { useToast } from 'primevue/usetoast';
import TemplateEditorNavbar from './components/TemplateEditorNavbar.vue';
import { useTemplate } from '@/pages/Templates/useTemplate.js';
import axios from 'axios';
import { displayErrorMessagesAsToast } from '@/utils/errorUtils.js';
import { useTipTapStore } from '@/tiptap/stores/useTipTapStore.js';
import { usePusher } from '@/hooks/usePusher.js';
import TemplateSettings from '@/pages/Templates/components/TemplateSettings.vue';

const toast = useToast();
usePusher();
const page = usePage();
const {template} = useTemplate();
const tiptapStore = useTipTapStore();

onMounted(() => {
    console.log('Set Current Template', template);
    tiptapStore.setCurrentTemplate(template.value.ulid);
})

const settingsPanel = ref(false);

function onSubmit() {}
async function onUpdateTemplate(value) {
    console.log('Update Template', template);
    await axios.put(`/templates/${template.value.ulid}`, {
        content: value,
        contentStringify: JSON.stringify(value),
        name: template.value.name,
    })
        .then(() => {
            tiptapStore.setSaving(false);
            tiptapStore.setDirty(false);
        })
        .catch((err) => {
            displayErrorMessagesAsToast(err, tfilast)
        });
}

function openSettings() {
    settingsPanel.value = true;
}
</script>
<style scoped>

.page-nav {
    z-index: 100;
    @apply sticky top-12 right-24;
}


</style>
