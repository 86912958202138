<template>
    <div
        class="flex relative room-view py-1 justify-center px-8 lg:px-0"
    >
        <div class="flex w-full lg:justify-normal lg:flex-col">
            <div class="flex lg:flex-col">
                <div class="flex items-center space-x-2 lg:mb-12 mt-3">

                </div>
                <div class="flex lg:flex-col items-center lg:items-start ml-8 mt-3 lg:mt-0 lg:ml-0">
                    <div class="text-gray-500 px-3 text-xs font-medium mb-1">PAGES</div>
                    <VueDraggableNext v-model="pages" @end="onOrder">
                        <div v-for="page in pages"
                             :key="page.id"
                             class="flex items-center w-full"
                        >
                            <div
                                class="w-full"
                                :class="{'bg-gray-100 rounded-md': page.id === currentPage.id}"
                            >
                                <RoomNavItem
                                    :page="{...page}"
                                    @on-delete="() => onDelete(page)"
                                    @on-edit="() => onEditPage(page)"
                                    @on-edit-emoji="(emoji) => onEditEmoji(page, emoji)"
                                />
                            </div>
                        </div>
                    </VueDraggableNext>
                </div>
            </div>
            <div class="flex items-center justify-start mt-3 lg:mt-6" v-if="editable">
                <Button variant="ghost" link @click="addPage = true">
                    <PlusIcon class="w-4 h-4"></PlusIcon>
                    <span class="text-sm text-gray-950 font-normal">Add Page</span>
                </Button>
            </div>
        </div>


        <Dialog v-model:visible="editPage" modal header="Edit page" :style="{ width: '25rem' }"  content-style="overflow: visible;">
            <BaseFormCard @submit="editPageSubmit" :form="editPageForm" style="height: 180px; overflow: visible;">
                <div class="flex align-items-center gap-3 mb-3">
                    <FormField v-slot="{ componentField, value }" name="name">
                        <FormItem v-auto-animate class="w-full">
                            <FormLabel>Page name</FormLabel>
                            <FormControl>
                                <InputText v-bind="componentField" @input="e =>addPageForm.vee.setFieldValue('name', e?.target?.value || e)" />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </div>
                <template #footer>
                    <div class="flex justify-center gap-2">
                        <Button type="button" size="small" label="Cancel"
                                variant="secondary"
                                class="min-w-24"
                                @click="editPage = false">
                            Cancel
                        </Button>
                        <Button type="submit" size="small" data-pc-severity="custom-primary" class="min-w-24" label="Save"
                                :loading="editPageForm.processing"
                                >
                            Save
                        </Button>
                    </div>
                </template>
            </BaseFormCard>
        </Dialog>

        <Dialog v-model:visible="addPage" modal header="Add new page" :style="{ width: '25rem' }" content-style="overflow: visible;">
            <BaseFormCard @submit="createNewPage" :form="addPageForm"  style="height: 180px; overflow: visible;">
                <div class="flex align-items-center gap-3 mb-3">
                    <FormField v-slot="{ componentField }" name="name">
                        <FormItem v-auto-animate class="w-full">
                            <FormLabel>Page name</FormLabel>
                            <FormControl>
                                <InputText
                                    v-bind="componentField"
                                    @input="e =>addPageForm.vee.setFieldValue('name', e?.target?.value || e)"
                                />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </div>
                <template #footer>
                    <div class="flex justify-center gap-2">
                        <Button type="button" size="small" label="Cancel"
                                variant="secondary"
                                class="min-w-24"
                                @click="addPage = false">
                            Cancel
                        </Button>
                        <Button type="submit" size="small" class="min-w-24" label="Save"
                                :loading="addPageForm.processing"
                        >
                            Save
                        </Button>
                    </div>
                </template>
            </BaseFormCard>
        </Dialog>
        <ConfirmDialog></ConfirmDialog>
    </div>
</template>
<script setup>

import { usePage } from '@inertiajs/vue3';
import { computed, ref } from 'vue';
import { useRoomPage } from '../hooks/useRoomPage.js';
import ConfirmDialog from 'primevue/confirmdialog';
import RoomNavItem from './RoomNavItem.vue';
import { PlusIcon } from 'lucide-vue-next';
import Button from '../../../tiptap/components/ui/button/Button.vue';
import BaseFormCard from '@/Components/forms/BaseFormCard.vue';
import { FormControl, FormField, FormItem, FormLabel, FormMessage, } from '@/Components/ui/form'
import { VueDraggableNext } from 'vue-draggable-next';
import InputWithEmoji from '@/Components/ui/input/InputWithEmoji.vue';

const props = defineProps({
    room: {
        type: Object,
        default: null
    },
    editor: {
        type: Object,
        default: null
    },
    items: {
        type: Array,
        default: () => []
    }
})

const emit = defineEmits(['save'])
const page = usePage();

const room = ref(props.room || page.props.room);
const currentPage = computed(() => {
    return page.props.currentPage;
})

const {
    pages,
    addPage,
    editPage,
    addPageForm,
    editPageForm,
    onDelete,
    onEditPage,
    onEditEmoji,
    editPageSubmit,
    createNewPage,
    editable,
} = useRoomPage(room.value);

async function onOrder(e) {
    try {
        await axios.post('/page/reorder', {
            pages: pages.value.map((page, index) => {
                return {
                    ulid: page.ulid,
                    order: index
                }
            })
        })
    } catch (e) {

    }
}
</script>
<style>
</style>
