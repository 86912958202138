<template>
    <div
        class="max-h-[calc(100vh-110px)] overflow-y-auto p-0.5 px-1"
    >
        <form @submit.prevent="onSubmit" :class="formClass">
            <slot></slot>
            <template v-if="$slots.footer">
                <slot name="footer"></slot>
            </template>
            <template v-else>
                <div :class="{
                'bg-white z-10 sticky bottom-0 border-r border-l border-b border-gray-200 rounded-b-xl px-6 py-4' : !props.modal
                }">
                    <slot name="footer"></slot>
                    <div class="w-full flex justify-end space-x-2" v-if="!$slots.footer" >
                        <Button class="min-w-[80px]" variant="secondary" size="small" @click="onCancel">
                            {{ cancelText }}
                        </Button>
                        <Button class="min-w-[80px]" size="small" type="submit" :loading="loading || form.processing">
                            {{ saveText }}
                        </Button>
                    </div>
                </div>
            </template>
        </form>
    </div>
</template>
<script setup>

import Button from '../../tiptap/components/ui/button/Button.vue';
import { Form } from 'vee-validate';
const props = defineProps({
    loading: {
        type: Boolean,
    },
    saveText: {
        type: String,
        default: 'Submit',
    },
    cancelText: {
        type: String,
        default: 'Cancel',
    },
    onCancel: {
        type: Function,
        default: null
    },
    form: {
        type: Object,
        default: null
    },
    modal: {
        type: Boolean,
        default: false
    },
    formClass: {
        type: String,
    }
})

const emit = defineEmits(['submit', 'cancel'])

async function onSubmit() {
    if (props.form) {
        const result = await props.form.validate();

        result.valid && emit('submit')
    } else {
        emit('submit')
    }
}

function onCancel() {
    if (props.onCancel) {
        props.onCancel();
    } else {
        window.history.back()
    }
}
</script>
<style scoped>

</style>
