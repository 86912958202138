<template>
    <node-view-wrapper
        as="div"
        class="resizable-node"
        :class="{'readonly': !editor?.isEditable }"
    >
        <Toast />
        <RoomCard>
            <template #header>
                <div class="flex w-full justify-between items-center gap-2">
                    <RoomCardTitle>
                        <InputEdit
                            :editable="editable"
                            :modelValue="planTitleForm.title"
                            @update:modelValue="value => planTitleForm.title = value"
                            @submit="onSubmitPlanTitle"
                        />
                    </RoomCardTitle>
                    <div class="gap-2 flex items-center text-xs text-gray-500">
                        <circle-progress
                            v-tooltip="currentPlan?.total_amount_owned > 0 ? `${Math.floor(currentPlan?.total_comsumption / currentPlan?.total_amount_owned * 100)}%` : `0%`"
                            :is-gradient="false"
                            :size="30"
                            :border-width="4"
                            :border-bg-width="4"
                            :percent="currentPlan?.total_amount_owned > 0 ? Math.floor(currentPlan?.total_comsumption / currentPlan?.total_amount_owned * 100) : 0"
                        />
                    </div>
                </div>
            </template>
            <div class="flex flex-col space-y-4  py-4" v-if="!loading && !error">

                <div class="py-1 rounded-lg group" v-for="todo in todos" :key="todo.id">
                    <div class="flex items-start justify-between ">
                        <div class="flex items-start overflow-x-auto">
                            <div class="ml-2 text-base flex-wrap text-wrap flex-1 overflow-x-hidden truncate">{{ todo.title }}</div>
                        </div>
                        <div class="flex gap-2 items-center flex-1 justify-end">
                            <div class="opacity-0 group-hover:opacity-100 flex" v-if="canModifyPlanItem() && !isCollaborator()">
                                <Button
                                    variant="ghost"
                                    @click="removeTodo(todo)"
                                >
                                    <Icon name="Trash2"/>
                                </Button>
                                <Button
                                    variant="ghost"
                                    @click="todoDetail = todo"
                                >
                                    <Icon name="Pencil"/>
                                </Button>
                            </div>
                            <SoftwareAdoptionStatus :status="todo.status" />

                            <Button
                                v-if="todo.action === 'external_link'"
                                @click="() => openExternalLink(todo)"
                                variant="primary"
                                button-size="small"
                                class="flex items-center text-xs"
                            >
                                {{todo.action_name}}

                                <i class="pi pi-arrow-up-right"></i>
                            </Button>

                            <div class="ml-4 flex flex-row w-8 h-8" v-if="todo.members.length">
                                <BaseAvatar
                                    v-for="member in todo.members"
                                    :key="member.id"
                                    :src="member.avatar"
                                    :name="member.name"
                                    :title="member.name"
                                    class="w-8 h-8 rounded-full -ml-4"
                                />
                            </div>

                            <circle-progress
                                v-tooltip="todo.amount_owned > 0 ? `${Math.floor(todo.consumption / todo.amount_owned * 100)}%` : `0%`"
                                :is-gradient="false"
                                :size="30"
                                :border-width="4"
                                :border-bg-width="4"
                                :percent="todo.amount_owned > 0 ? `${Math.floor(todo.consumption / todo.amount_owned * 100)}` : `0%`"
                            />
                        </div>
                    </div>
                </div>
                <div class="flex items-center" v-if="editable">
                    <form @submit.prevent="addTodo" class="flex items-end w-full">
                        <FormField name="name">
                            <FormItem v-auto-animate class="w-full">
                                <FormControl>
                                    <div class="flex">
                                        <Input
                                            v-model="newTodoTitle"
                                            placeholder="Add new software"
                                            class="rounded-r-none border-r-0"
                                        />
                                        <Button
                                            class="rounded-l-none !border-l-0"
                                            variant="secondary"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        </FormField>
                    </form>
                </div>

            </div>
            <CardError v-if="!loading && error"/>

            <template v-if="todoDetail">
                <SoftwareAdoptionItemDetail
                    :todo-detail="todoDetail"
                    @close="() => {
                        todoDetail = null;
                        getSoftwareAdoption(props.node.attrs.id)
                    }"
                />
            </template>

        </RoomCard>
    </node-view-wrapper>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { NodeViewWrapper } from '@tiptap/vue-3';
import { useForm } from '@inertiajs/vue3';
import { useRoomPage } from '../../../../pages/RoomDetail/hooks/useRoomPage.js';
import { displayErrorMessagesAsToast } from '../../../../utils/errorUtils.js';
import { FormControl, FormField, FormItem, FormMessage, } from '@/Components/ui/form'
import { Input } from '@/Components/ui/input'
import InputEdit from '../../../../Components/InputEdit.vue';
import Button from '../../../components/ui/button/Button.vue';
import BaseAvatar from '../../../../Components/forms/BaseAvatar.vue';
import CardError from '../../../components/ui/CardError.vue';
import RoomCard from '../../../../pages/RoomDetail/components/RoomCard.vue';
import Icon from '../../../components/ui/Icon.vue';
import RoomCardTitle from '../../../../pages/RoomDetail/components/RoomCardTitle.vue';
import { useAuth } from '../../../../hooks/useAuth.js';
import SoftwareAdoptionItemDetail from './SoftwareAdoptionItemDetail.vue';
import { softwareAdaptionItemStatuses } from '../statuses.js';

import 'vue3-circle-progress/dist/circle-progress.css';
import CircleProgress from 'vue3-circle-progress';
import SoftwareAdoptionStatus from './SoftwareAdoptionStatus.vue';

const props = defineProps({
    editor: Object,
    node: Object,
    editable: Boolean,
    form: Object,
    onSubmit: Function,
})

const todoDetail = ref(null);
const uploadDialog = ref(null);
const downloadDialog = ref(null);
const { canModifyPlanItem, isCollaborator } = useAuth();
const {
    editable,
    softwareAdoptionItemBaseUrl
} = useRoomPage();
const currentHeader = ref(null);
const loading = ref(true);
const newTodoTitle = ref('');
const form = useForm({
    name: undefined,
    account_avatar: undefined,
    tenant_avatar: undefined,
    cover: undefined,
});

const error = ref(false);

const items = computed(() => {
    return currentPlan.value?.items || []
})

const currentPlan = ref(null);
const todos = computed(() => {
    return items.value.map((item) => {
        return {
            ...item,
            completed: !!item.completed_at
        }
    })
});

const planTitleForm = useForm({
    title: currentPlan.value?.title,
});


onMounted(() => {
    getSoftwareAdoption(props.node.attrs.id)
});

async function getSoftwareAdoption(id) {
    try {
        const {data} = await axios.get('/software-adoption/' + id)
        currentPlan.value = data
        planTitleForm.title = data.title
    } catch (e) {
        error.value = true;
        console.error(e)
    } finally {
        loading.value = false
    }
}

function updateTodos(data) {
    currentPlan.value = data.software_adoption;
}

function addTodo() {
    const trimmedTitle = newTodoTitle.value.trim();
    if (!trimmedTitle) {
        return; // Exit if the title is empty
    }

    axios.post(softwareAdoptionItemBaseUrl.value, {
        title: trimmedTitle,
        software_adoption_id: currentPlan.value.id,
    }).then(response => {
        updateTodos(response.data); // Assuming updateTodos function processes and updates the state with the new todo data
    }).catch(error => {
        displayErrorMessagesAsToast(error); // Handling errors by showing them in a toast message
    }).finally(() => {
        newTodoTitle.value = ''; // Clear the input field in any case after the request
    });
}

function removeTodo(todo) {
    axios.delete(`${softwareAdoptionItemBaseUrl.value}/${todo.id}`)
        .then(response => {
            updateTodos(response.data);
        })
        .catch(error => {
            displayErrorMessagesAsToast(error);
        })
        .finally(() => {
        });
}


function onUpdateCompletedAt(value, todo) {
    todo.completed_at = !!value;

    axios.put(`${softwareAdoptionItemBaseUrl.value}/${todo.id}`, {
        title: todo.title,
        completed: value,
        members: todo.members.map(member => member.id),
    }).then(response => {
        updateTodos(response.data);
    }).catch(error => {
        displayErrorMessagesAsToast(error);
    }).finally(() => {
    });
}

function onSubmitPlanTitle() {
    if (!editable) {
        return;
    }

    axios.put(`/software-adoption/${currentPlan.value.id}`, {
        title: planTitleForm.title,
    }).then(response => {
    }).catch(error => {
        planTitleForm.title = currentPlan.value?.title
        displayErrorMessagesAsToast(error)
    }).finally(() => {
    });
}

function getTaskStatusColor(status) {
    const findStatus = softwareAdaptionItemStatuses.find((s) => s.value === status);

    return findStatus?.color || '#4ECB71';
}

function openExternalLink(todo) {
    window.open(todo.action_url, '_blank');
}

</script>
<style lang="scss">
.task-modal {
    .ProseMirror {
        padding: 0;
    }

    .p-dialog {
        @apply h-screen;
    }
}
.p-sidebar-mask {
    z-index: 999 !important;
}
//.p-checkbox-input:hover {
//    background: white !important;
//}
.p-checkbox-box {
    @apply bg-white;
}
.p-checkbox-icon {
    @apply text-primary-500 text-xs;
}
//.peer:hover ~ .peer-hover\:bg-primary-600 {
//    background: white !important;
//}

.p-badge-warning {
    color: #FF9A62;
    background: transparent !important;
}
.p-badge-success {
    color: #4ECB71;
    background: transparent !important;
}
.p-badge-info {
    @apply text-blue-500;
    background: transparent !important;
}
</style>
