import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import TemplateCardNodeTemplate from './components/TemplateCardNodeTemplate.vue';

export const templateSuggestion = Node.create({
    name: 'template',

    group: 'block',

    addAttributes() {
        return {
            id: {
                default: null,
            },
            data: {
                default: null,
            },

            isDraggable: {
                default: true,
                renderHTML: (attributes) => {
                    return {};
                }
            },
            inline: {
                default: false,
            },
        }
    },

    parseHTML() {
        return [{
            tag: 'template'
        }]
    },

    renderHTML({ HTMLAttributes }) {
        return ['template', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(TemplateCardNodeTemplate);
    },

    addCommands() {
        return {
            setTemplate: (options) => ({ commands, tr, dispatch, chain }) => {
                const { selection } = tr

                const node = this.type.create(options)

                if (dispatch) {
                    tr.replaceRangeWith(selection.from, selection.to, node)
                }

                return true;
            },

        }
    },
});

export default templateSuggestion;
