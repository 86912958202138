import { ref } from 'vue';

export function useTemplateCategoryType() {
    const categories = [{
        name: 'Sales',
        color: '#4ECB71',
        value: 'sales',
    }, {
        name: 'Customer Success',
        color: '#FFD233',
        value: 'customer_success',
    }, {
        name: 'Investors',
        color: '#FF9A62',
        value: 'investors',
    }, {
        name: 'Proposals',
        color: '#62d0ff',
        value: 'proposals',
    }, {
        name: 'Agency',
        color: '#f2cf1e',
        value: 'agency',
    }, {
        name: 'General',
        color: '#7d1ef2',
        value: 'general',
    }]

    return {
        categories
    }
}
