<template>
    <AuthenticatedLayout>
        <template #title>Templates</template>
        <div class="flex justify-between items-center">
            <div class="flex flex-col">
                <p class="pl-2 text-gray-700 text-sm">Room templates to get you started.</p>
            </div>

            <CreateButton @click="onCreateTemplate">
                Create Template
            </CreateButton>
        </div>


        <TemplateSelection />
    </AuthenticatedLayout>
</template>
<script setup>
import 'vue3-circle-progress/dist/circle-progress.css';
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import { router, useForm } from '@inertiajs/vue3';
import { displayErrorMessagesAsToast } from '@/utils/errorUtils.js';
import CreateButton from '@/Components/buttons/CreateButton.vue';
import TemplateSelection from '@/pages/Templates/components/TemplateSelection.vue';
import { useToast } from 'primevue/usetoast';
const toast = useToast()
const form = useForm({});
function onCreateTemplate() {
    form
        .transform((data) => {
            return {
                name: 'Untitled',
                template_type: 'blank'
            }
        })
        .post('/templates', {
            onSuccess: (data) => {
                const id = data.props.flash.template.ulid

                router.visit(`/templates/${id}`);
            },
            onError: (err) => {
                displayErrorMessagesAsToast(err, toast)
            },
        });
}

</script>
