<template>
    <div class="flex w-full">
        <div class="flex space-x-4 mt-4 gap-4 w-full">
            <aside class="w-[200px]">
                <FormField v-slot="{ componentField }" name="test"  class="w-full">
                    <FormItem v-auto-animate class="w-full">
                        <FormControl>
                            <InputWithIcon
                                :icon="SearchIcon"
                                v-bind="componentField"
                                v-model="searchModel"
                                class="w-full"
                                placeholder="Search..."
                            />
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>

                <div>
                    <h3 class="text-gray-950 text-sm mt-8 mb-4 ">Categories</h3>
                    <ul class="space-y-1 text-gray-700 text-sm flex flex-col gap-1.5 w-[200px]">
                        <li v-for="category in categories" :key="category" class="cursor-pointer hover:text-blue-500" :class="{
                            'text-blue-500': selectedCategory === category.value
                        }" @click="selectCategory(category)">
                            {{ category.name }}
                        </li>
                    </ul>
                </div>
            </aside>

            <div class="flex flex-wrap gap-4"  v-if="templates.length > 0">
                <div
                    v-for="template in displayedTemplates"
                    :key="template.ulid"
                    class="col"
                >
                    <TemplateCard
                        :template="template"
                        @insertContent="() => {
                          emit('insertContent', template.content)
                        }"
                        @preview="() => {
                          previewTemplate = template;
                        }"
                    />
                </div>
            </div>

            <div v-else class="w-full flex items-center justify-center text-gray-600 text-2xl">
                No templates found
            </div>
            <!-- Card Grid -->

            <TemplatePreviewDialog
                :visible="!!previewTemplate"
                :template="previewTemplate"
                @hide="() => {
                    previewTemplate = null;
                }"
            />
        </div>
    </div>

    <div ref="loadMoreTrigger" class="h-4 w-full"></div>
</template>
<script setup>
import 'vue3-circle-progress/dist/circle-progress.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { computed, onMounted, ref, watch } from 'vue';
import TemplateCard from '@/pages/Templates/components/TemplateCard.vue';
import { FormControl, FormField, FormItem, FormMessage } from '@/Components/ui/form/index.js';
import { InputWithIcon } from '@/Components/ui/input'
import { usePage } from '@inertiajs/vue3';
import { useTemplateCategoryType } from '@/hooks/useTemplateCategoryType.js';
import { useAuth } from '@/hooks/useAuth.js';
import { DynamicScroller } from 'vue-virtual-scroller';
import { SearchIcon } from 'lucide-vue-next';
import TemplatePreviewDialog from '@/pages/Templates/components/TemplatePreviewDialog.vue';
import { useElementVisibility } from '@vueuse/core';

const emit = defineEmits(['insertContent']);
const { user } = useAuth();
const rowHeight = 400;
const visibleRows = 3;
const previewTemplate = ref(null);
const page = usePage();
const tabs = ref([
    { name: 'Team', value: 'team' },
    { name: 'Community', value: 'community' },
]);
const {categories} = useTemplateCategoryType();
const selectedCategory = ref('all');

categories.unshift({
    name: 'My Favorites',
    value: 'favourites'
})

categories.unshift({
    name: 'My Templates',
    value: 'my'
})

categories.unshift({
    name: 'All',
    value: 'all'
})


const templates = computed(() => {
    const templates = page.props.templates.filter(template => {
        return template.name.toLowerCase().includes(searchModel.value.toLowerCase());
    }).filter(template => {
        if (! template.published && template.created_by !== user.id) {
            return false;
        }

        return true;
    });

    if (selectedCategory.value === 'all') {
        return templates;
    }

    if (selectedCategory.value === 'favourites') {
        return templates.filter(template => template.starred_by.find(pivot => pivot.pivot.user_id === user.id));
    }

    if (selectedCategory.value === 'my') {
        return templates.filter(template => template.created_by === user.id);
    }

    return templates.filter(template => template.category === selectedCategory.value);
})

const rows = computed(() => {
    const perRow = 4; // Number of items per row
    return templates.value.reduce((acc, curr, index) => {
        const rowIndex = Math.floor(index / perRow);
        if (!acc[rowIndex]) acc[rowIndex] = [];
        acc[rowIndex].push(curr);
        return acc;
    }, []).map((row, index) => ({ rowKey: `row-${index}`, items: row }));
})

const searchModel = ref('');
const loadMoreTrigger = ref(null);
const isLoadMoreVisible = useElementVisibility(loadMoreTrigger);
const pageNr = ref(1);
const itemsPerPage = 12; // Adjust this number as needed
const loading = ref(false);
const displayedTemplates = ref([]);

function selectCategory(category) {
    selectedCategory.value = category.value;
}
onMounted(() => {
    loadMoreTemplates();
});

// Watch for scroll
watch(isLoadMoreVisible, async (isVisible) => {
    if (isVisible && !loading.value) {
        await loadMoreTemplates();
    }
});

async function loadMoreTemplates() {
    if (loading.value) return;

    loading.value = true;

    const start = (pageNr.value - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    await new Promise(resolve => setTimeout(resolve, 100));

    const newTemplates = templates.value.slice(start, end);

    if (newTemplates.length > 0) {
        displayedTemplates.value = [...displayedTemplates.value, ...newTemplates];
        pageNr.value++;
    }

    loading.value = false;
}
</script>
<style lang="scss">
.vue-recycle-scroller {
    @apply w-full
}
.row {
    @apply flex gap-4;
}

.col {
    display: flex;
    justify-content: center;
    align-items: center;
    @apply flex items-center justify-center w-[290px]
}
</style>
