import { usePage } from '@inertiajs/vue3';
import { computed, ref, watch } from 'vue';

export function useTemplate() {
    const page =  usePage();
    const template = computed(() => page.props.template);

    return {
        template,
    }
}
