import { API } from '@/tiptap/lib/api'

import {
    BlockquoteFigure,
    CharacterCount,
    Color,
    Document,
    Dropcursor,
    Emoji,
    Figcaption,
    FileHandler,
    Focus,
    FontFamily,
    FontSize,
    Heading,
    Highlight,
    HorizontalRule,
    Link,
    Placeholder,
    Selection,
    SlashCommand,
    StarterKit,
    Subscript,
    Superscript,
    Table,
    TableCell,
    TableHeader,
    TableRow,
    TextAlign,
    TextStyle,
    TrailingNode,
    Typography,
    Underline,
    emojiSuggestion,
    Columns,
    Column,
    TaskItem,
    TaskList,
    embedSuggestion,
    headerSuggestion,
    fileCardSuggestion,
    filePreviewSuggestion,
    priceCardSuggestion,
    Image,
    HeaderSpinnerNode,
    ProjectPlanSpinnerNode,
    projectPlanSuggestion,
    softwareAdoptionSuggestion,
    questionSuggestion,
    contactCardSuggestion,
    templateSuggestion,
    tableOfContexts,
    ImageBlock,
    // embedSuggestion,
} from '.'

import { CodeBlockLowlight } from '@tiptap/extension-code-block-lowlight'
import { createLowlight, common } from 'lowlight'
import { TableOfContents, getHierarchicalIndexes } from "@tiptap-pro/extension-table-of-contents";
import { nextTick } from "vue";
const lowlight = createLowlight(common)

export const ExtensionKit = () => [
  Document,
  Columns,
  TaskList,
  HeaderSpinnerNode,
  TaskItem.configure({
    nested: true,
  }),
  TableOfContents.configure({
      anchorTypes: ['heading'],
      getIndex: getHierarchicalIndexes,
      onUpdate: content => {
          nextTick(() => {
            window.dispatchEvent(new CustomEvent('toc-update', { detail: content }));
          })
      },
  }),
  Column,
  Selection,
  Heading.configure({
    levels: [1, 2, 3, 4, 5, 6],
  }),
  HorizontalRule,
  StarterKit.configure({
    document: false,
    dropcursor: false,
    heading: false,
    horizontalRule: false,
    blockquote: false,
    codeBlock: false,
  }),
  CodeBlockLowlight.configure({
    lowlight,
    defaultLanguage: null,
  }),
  TextStyle,
  FontSize,
  FontFamily,
  Color,
  fileCardSuggestion,
  tableOfContexts,
  filePreviewSuggestion,
  ProjectPlanSpinnerNode,
  projectPlanSuggestion,
  softwareAdoptionSuggestion,
  questionSuggestion,
  contactCardSuggestion,
  templateSuggestion,
  Image,
  TrailingNode,
  Link.configure({
    openOnClick: false,
  }),
  headerSuggestion,
  priceCardSuggestion,
  Highlight.configure({ multicolor: true }),
  Underline,
  CharacterCount.configure({ limit: 50000 }),
  ImageBlock,
  FileHandler.configure({
    allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
    onDrop: (currentEditor, files, pos) => {
      files.forEach(async () => {
        const url = await API.uploadImage()

        currentEditor.chain().setImageBlockAt({ pos, src: url }).focus().run()
      })
    },
    onPaste: (currentEditor, files) => {
      // files.forEach(async () => {
      //   const url = await API.uploadImage()
      //
      //   return currentEditor
      //     .chain()
      //     .setImageBlockAt({ pos: currentEditor.state.selection.anchor, src: url })
      //     .focus()
      //     .run()
      // })
    },
  }),
  Emoji.configure({
    enableEmoticons: true,
    suggestion: emojiSuggestion,
  }),
  embedSuggestion,
  TextAlign.extend({
    addKeyboardShortcuts() {
      return {}
    },
  }).configure({
    types: ['heading', 'paragraph'],
  }),
  Subscript,
  Superscript,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Typography,
  Placeholder,
  SlashCommand,
  Focus,
  Figcaption,
  BlockquoteFigure,
  Dropcursor.configure({
    width: 2,
    class: 'ProseMirror-dropcursor border-black',
  }),
]

export default ExtensionKit
