import './bootstrap';
import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import primeVuePlugin from "./primeVuePlugin.js";
import { createPinia } from 'pinia';
import VueApexCharts from "vue3-apexcharts";
import VueTailwindDatepicker from "vue-tailwind-datepicker";
import axiosInstance from '@/utils/axiosInstance.js';
import VueVirtualScroller from 'vue-virtual-scroller'
import { RecycleScroller } from 'vue-virtual-scroller'

createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./pages/**/*.vue', { eager: true })
        return pages[`./pages/${name}.vue`]
    },
    setup({ el, App, props, plugin }) {
        const pinia = createPinia()

        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(primeVuePlugin)
            .use(pinia)
            .use(VueTailwindDatepicker)
            .use(VueApexCharts)
            .use(VueVirtualScroller)
            .use(RecycleScroller)
            // .use({
            //     install(app) {
            //         // eslint-disable-next-line no-undef
            //         app.config.globalProperties.$route = route;
            //     },
            // })

        console.log(app.config.globalProperties);
        app.provide('$axios', axiosInstance);

        app.mount(el)
    },
})
