<template>
    <div class="rounded-lg border border-gray-300 flex flex-col justify-start min-h-[380px] max-w-[290px] h-full bg-gray-25 group w-full">
        <img :src="template.avatar || 'https://placehold.co/160'"
             alt="Template image"
             class="w-full h-40 object-cover rounded-t-lg cursor-pointer"
             width="290"
             height="160"
             @click="onClickTemplate"
        />

        <div class="p-4 flex-1 flex flex-col">
            <div class="flex flex-col overflow-hidden relative">
                <h3 class="text-gray-950 text-sm cursor-pointer truncate" @click="onClickTemplate" >
                    {{ template.name }}
                </h3>
                <div v-if="template.categoryName">
                  <span class="text-gray-500 text-[10px] text-bold px-1 py-0.5 bg-white border border-gray-500 rounded-md w-auto uppercase" >
                      {{template.categoryName}}
                  </span>
                </div>
              <div class="text-gray-500 text-[10px] text-bold flex items-center gap-2 my-2">
                  <BaseAvatar
                      :src="template.author?.avatar"
                      :name="template.author.name"
                      class="w-4 h-4 rounded-full"
                  />
                  {{template.author.first_name }} {{ template.author.last_name}}
              </div>
                <p class="text-gray-950 text-sm text-clip overflow-hidden ellipsis-multiline" :title="template.description">
                    {{ template.description }}
                </p>
            </div>

            <div class="flex items-center justify-between text-gray-950 text-sm mt-auto border-t pt-4 border-dashed">
                <div class="flex items-center space-x-2 " :class="{
                    'group-hover:hidden': true
                }">
                    <span class="flex items-center space-x-1">
                      <Icon name="CircleArrowDown"/>
                      <span>{{ template.downloads }}</span>
                    </span>
                    <span class="flex items-center space-x-1">
                      <Icon name="Star"/>
                      <span>{{ template.starred_by.length || 0 }}</span>
                    </span>
                </div>
                <div class="items-center space-x-2 hidden group-hover:flex">
                    <Button buttonSize="small" variant="secondary" @click="goToTemplate" class="h-[28px]" v-if="isAuthor">
                        <Icon name="Pencil" class="h-4" />
                        Edit
                    </Button>
                    <Button buttonSize="small" variant="secondary" @click="onView" class="h-[28px]" v-else>
                        <Icon name="Eye" class="h-4" />
                        View
                    </Button>
                </div>
                <Button v-if="!isInsideRoom()"  @click="createRoom" buttonSize="small" variant="outline" class="h-[28px]" :loading="loading">
                    <span v-if="!loading">+</span> Create room
                </Button>
                <Button v-else  @click="insertContent" buttonSize="small" variant="outline" class="h-[28px]">
                    Insert Content
                </Button>
            </div>
        </div>
    </div>
</template>

<script setup>
import Icon from '@/tiptap/components/ui/Icon.vue';
import { router, useForm } from '@inertiajs/vue3'
import { displayErrorMessagesAsToast } from '@/utils/errorUtils.js';
import { useToast } from 'primevue/usetoast';
import { useTipTapStore } from '@/tiptap/stores/useTipTapStore.js';
import { computed, ref } from 'vue';
import { useAuth } from '@/hooks/useAuth.js';
import Button from '@/tiptap/components/ui/button/Button.vue';
import BaseAvatar from '@/Components/forms/BaseAvatar.vue';

const toast = useToast()
const {user} = useAuth();

const currentTemplateUlid = ref(false);
const tiptapStore = useTipTapStore();
tiptapStore.$subscribe(function (state) {
    currentTemplateUlid.value = tiptapStore.currentTemplateUlid;
})


const isAuthor = computed(() => {
    return user?.id === props.template.created_by;
})

const emit = defineEmits(['insertContent', 'preview'])

const props = defineProps({
    template: {
        image: {
            type: String,
            default: 'https://placehold.co/160'
        },
        title: String,
        description: String,
        downloads: Number,
        stars: Number,
        ulid: String,
        content: String,
        created_by: Number
    }
});

console.log('template', props.template);

function goToTemplate(){
    router.visit('/templates/' + props.template.ulid);
}

function onClickTemplate() {
    if (isAuthor.value) {
        goToTemplate();
    } else {
        onView();
    }
}

const loading = ref(false);
const form = useForm({
    name: 'Untitled',
    template_type: 'blank',
    template_ulid: props.template.ulid
});

function createRoom(){
    loading.value = true;
    form
        .post('/rooms', {
            onSuccess: (data) => {
                const id = data.props.flash.room.ulid
                const pageId = data.props.flash.room?.pages?.[0]?.ulid;

                if (pageId) {
                    router.visit(`/room-detail/${id}/${pageId}`);
                }
            },
            onError: (err) => {
                displayErrorMessagesAsToast(err, toast)
            },
            onFinish: () => {
                loading.value = false;
            }
        });
}

function onView() {
    emit('preview');
}

function insertContent() {
    emit("insertContent")
}

function isInsideRoom() {
    return window?.location?.href.includes('/room-detail')
}
</script>

<style scoped>
.truncate {
    white-space: nowrap; /* Prevents wrapping */
    overflow: hidden;    /* Hides overflow */
    text-overflow: ellipsis; /* Adds "..." at the end */
    max-width: 100%; /* Ensure the element doesn't expand beyond the parent */
    width: 100%; /* Ensures consistent behavior */
}
.ellipsis-multiline {
    display: -webkit-box; /* Creates a flexible box layout */
    -webkit-box-orient: vertical; /* Sets the box's orientation to vertical */
    -webkit-line-clamp: 3; /* Number of lines to display before truncating */
    overflow: hidden; /* Hides overflow */
    text-overflow: ellipsis; /* Adds "..." to indicate truncation */
    max-height: 4.5em; /* Adjust based on line height (3 lines × 1.5em line height) */
    line-height: 1.5em; /* Ensure consistent line spacing */
}
</style>
