<template>
    <node-view-wrapper
        as="div"
        class="resizable-node"
        :class="{'readonly': !editor?.isEditable, '!max-w-full': node.attrs.fullWidth, '!mx-24': node.attrs.fullWidth && editor.isEditable }"
    >
        <HeaderSpinner v-if="loading"/>
        <div class="bg-white rounded-3xl" v-if="!loading && !error">
            <div
                class="bg-blue-500 h-48 flex items-center px-4 relative rounded-2xl"
                :style="{
                'background-color': color,
            }"
            >
                <div class="flex items-center justify-between absolute bottom-[-25%]">
                    <div class="flex items-center gap-6 rounded-3xl bg-white py-3 px-8 border " :style="{borderColor: color}">
                        <div class="">
                            <AvatarUpload
                                :disabled="!editable"
                                :canCrop="false"
                                :name="form.account_avatar"
                                v-model="form.account_avatar"
                                :show-button="false"
                                @update:modelValue="onSubmit"
                                :submit="(file) => onSubmit(file, 'account_avatar')"
                                :show-help="false"
                                class="p-0 h-[100px] rounded-md !object-contain"
                                :description="`Upload PNG, JPG, 50x50 or larger recommended`"
                            >
                                <template #default v-if="editable">
                                    <div>
                                        <Button tag="span" variant="secondary">Upload an image</Button>
                                    </div>
                                </template>
                            </AvatarUpload>
                        </div>
                        <!--                        <div class="flex space-x-1">-->
                        <!--                            <div class="rounded-lg bg-red-300 opacity-50 h-[24px] w-2 transform rotate-[20deg]"></div>-->
                        <!--                            <div class="rounded-lg bg-yellow-100 h-[24px] opacity-70 w-2 transform rotate-[20deg]"></div>-->
                        <!--                        </div>-->
                        <div class="">
                            <AvatarUpload
                                :disabled="!editable"
                                :canCrop="false"
                                :name="form.tenant_avatar"
                                v-model="form.tenant_avatar"
                                :show-button="false"
                                @update:modelValue="onSubmit"
                                :submit="(file) => onSubmit(file, 'tenant_avatar')"
                                :show-help="false"
                                class="p-0 h-[100px] rounded-md !object-contain"
                                :description="`Upload PNG, JPG, 50x50 or larger recommended`"
                            >
                                <template #default v-if="editable">
                                    <div>
                                        <Button tag="span" variant="secondary">Upload an image</Button>
                                    </div>
                                </template>
                            </AvatarUpload>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex gap-1.5 flex-row justify-end mt-4 mr-4 items-center" v-if="editable">
                <div v-for="item in pallete" >
                    <div
                        class="w-4 h-4 rounded-full cursor-pointer"
                        @click="onChangeColor(item.rgb)"
                        :style="{
                            'background-color': `${rgbToHex(item.rgb || [])} !important`,
                        }"
                    ></div>
                </div>

                <div>
                    <input
                        class="w-4 h-4 mt-[6px] cursor-pointer"
                        type="color"
                        v-model="color"
                        @blur="onChangeColor(color)"
                    >
                </div>
            </div>
            <div class="p-4 pb-10" :class="{'mt-14': !editable, 'mt-4': editable}">
                <InputEdit
                    :editable="editable"
                    :modelValue="form.name"
                    @update:modelValue="value => form.name = value"
                    @submit="onSubmit"
                    :textStyle="{ fontSize: '42px', lineHeight: '48px', fontWeight: 'bold'}"
                />
            </div>
        </div>
        <CardError v-if="!loading && error"/>
    </node-view-wrapper>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { NodeViewWrapper } from '@tiptap/vue-3';
import AvatarUpload from '@/Components/forms/AvatarUpload.vue';
import InputEdit from '@/Components/InputEdit.vue';
import { useForm } from '@inertiajs/vue3';
import { useRoomPage } from '../../../../pages/RoomDetail/hooks/useRoomPage.js';
import { displayErrorMessagesAsToast } from '../../../../utils/errorUtils.js';
import HeaderSpinner from './HeaderSpinner.vue';
import { getPalette, rgbToHex } from '../../../../utils/colorPalette.js';
import CardError from '../../../components/ui/CardError.vue';
import Button from '@/tiptap/components/ui/button/Button.vue';
import { useToast } from 'primevue/usetoast';

const toast = useToast();

const color = ref(null);
const error = ref(false);
const props = defineProps({
    editor: Object,
    node: Object,
    editable: Boolean,
    form: Object,
    onSubmit: Function,
    updateAttributes: {
        type: Function,
        required: true,
    },
})

const {
    editable,
} = useRoomPage();
const currentHeader = ref(null);
const loading = ref(true);
const pallete = ref([]);

const form = useForm({
    name: undefined,
    account_avatar: undefined,
    tenant_avatar: undefined,
});

onMounted(() => {
    getHeader(props.node.attrs.id)
});

async function getHeader(id) {
    try {
        const {data} = await axios.get('/headers/' + id)
        console.log('data', data);
        currentHeader.value = data
        form.name = data.name
        form.account_avatar = data.image_links.account_avatar
        form.tenant_avatar = data.image_links.tenant_avatar
        color.value = data.cover_color;
        let newPallete = {};

        if (data.image_links.account_avatar) {
            newPallete = {
                ...newPallete,
                ...await getPalette(data.image_links.account_avatar)
            }
        }

        if (data.image_links.tenant_avatar) {
            newPallete = {
                ...newPallete,
                ...await getPalette(data.image_links.tenant_avatar)
            }
        }

        pallete.value = newPallete;
    } catch (e) {
        error.value = true;
        console.error(e)
    } finally {
        loading.value = false

    }

}

async function onSubmit(file, key) {
    loading.value = true;
    let propertyKey = 'account_avatar';

    if (!editable) {
        loading.value = false;
        return;
    }

    try {
        // Prepare FormData for the request
        const formData = new FormData();
        formData.append('_method', 'put');
        formData.append('name', form.data().name);
        formData.append('cover_color', color.value);

        if (form.data().account_avatar instanceof File) {
            formData.append('account_avatar', form.data().account_avatar);
            propertyKey = 'account_avatar';
        } else if (form.data().tenant_avatar instanceof File) {
            formData.append('tenant_avatar', form.data().tenant_avatar);
            propertyKey = 'tenant_avatar';
        }

        formData.append(key, file);

        // Making the axios POST request
        const response = await axios({
            method: 'post',
            url: `/headers/${currentHeader.value.id}`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        });

        // Handle the successful response
        await getHeader(currentHeader.value.id);
        pallete.value = await getPalette(response.data.props.flash.header.image_links[propertyKey]);
    } catch (err) {
        // Handle errors
        displayErrorMessagesAsToast(err, toast);
    } finally {
        // Finalize the process
        loading.value = false;
    }
}

function onChangeColor(rgb) {
    color.value = typeof rgb === 'string' ? rgb : rgbToHex(rgb);

    const formData = new FormData();
    formData.append('_method', 'put');
    formData.append('cover_color', color.value);

    axios({
        method: 'post', // Using POST with _method override for PUT
        url: `/headers/${currentHeader.value.id}`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
        // Handle success, update UI or state as needed
    }).catch(error => {
        // Error handling
        displayErrorMessagesAsToast(error);
    }).finally(() => {
        // Always executed
        loading.value = false;
    });
}
</script>
<style lang="scss">
</style>
