<template>
    <Sidebar
        v-model:visible="showDialog"
        @hide="() => emit('close')"
        header="Edit template"
        position="right"
        class="w-full md:w-44rem overflow-x-hidden"
        style="width: 500px;"
    >
        <BaseFormCard @submit="submit" :loading="form.processing" :form="form">
            <div class="task-modal flex flex-col gap-4 text-sm px-2">
                <p class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-gray-950">Cover photo</p>
                <AvatarUpload
                    :canCrop="true"
                    :name="form.name"
                    v-model="form.avatar"
                    @update:modelValue="(e) => {
                        form.vee.setFieldValue('avatar', e);
                    }"
                    :cropper-props="{
                        'resize-image': {
                            'adjustStencil': false
                        },
                        defaultPosition: ({ visibleArea, coordinates, imageSize }) => {
                            console.log('default position',{
                                visibleArea,
                                coordinates,
                                imageSize
                            })
                            return {
                                left: imageSize.width / 2 - coordinates.width / 2,
                                top: imageSize.height / 2 - coordinates.height / 2,
                            }
                        },
                        defaultSize:({ visibleArea, imageSize, stencilRatio, sizeRestrictions }) => {
                            return {
                                width: imageSize.width,
                                height: imageSize.height,
                            }
                        },
                        'stencil-props': {
                            movable: false,
                            resizable: false,
                        },
                        'stencil-size': {
                            'width': 290,
                            'height': 160,
                        },
                        'image-restriction': 'stencil',
                    }"
                    :show-button="false"
                    :show-help="false"
                    class="p-0 h-48 w-full rounded-md !object-contain"
                    :description="`Upload PNG, JPG, 290x160 or larger recommended`"
                />
                <FormField v-slot="{ componentField }" name="name">
                    <FormItem v-auto-animate class="col-span-6">
                        <FormLabel>Template name</FormLabel>
                        <FormControl>
                            <Input v-bind="componentField"/>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>

                <FormField v-slot="{ componentField }" name="category">
                    <FormItem v-auto-animate class="col-span-6">
                        <FormLabel>Category</FormLabel>
                        <FormControl>
                            <Select v-bind="componentField">
                                <FormControl>
                                    <SelectTrigger>
                                        <SelectValue />
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent style="z-index: 9999">
                                    <SelectGroup>
                                        <SelectItem v-for="model in categories" :value="model.value">
                                            <div class="flex items-center gap-2">
                                                <div class="text-sm">{{ model.name }}</div>
                                            </div>
                                        </SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>

                <FormField v-slot="{ componentField, value }" name="description">
                    <FormItem v-auto-animate class="col-span-6">
                        <FormLabel>Description</FormLabel>
                        <FormControl>
                            <Textarea v-bind="componentField" :value="value" class="border w-full p-2" rows="8" placeholder="Insert description"/>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>

                <div class="flex justify-between items-end">
                    <FormField v-slot="{ componentField, value }" name="published">
                        <FormItem v-auto-animate class="col-span-6">
                            <FormLabel class="flex items-center gap-2">
                                Published
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger>
                                            <Icon name="CircleHelp" class="text-gray-750"/>
                                        </TooltipTrigger>
                                        <TooltipContent class="!z-9999">
                                            <p>Marking a template published makes it accessible to the community. Leave this off if you do not wish to share it.</p>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            </FormLabel>
                            <FormControl class="flex justify-between flex-row">
                                <Switch
                                    v-bind="componentField"
                                    :checked="value"
                                    @update:checked="e => form.vee.setValues({published: e})"
                                />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </div>
            </div>

            <template #footer>
                <div class="flex gap-2 mt-auto px-2">
                    <Button
                        @click="confirmDelete"
                        size="small"
                        aria-label="Delete"
                        class="w-full mt-4 bg-red-500 hover:bg-red-600 border-red-500 hover:border-red-600 confirm-button"

                    >
                        DELETE
                    </Button>
                    <Button
                        class="w-full mt-4"
                        size="small"
                        type="submit"
                        :loading="isSubmitting"
                    >
                        Save
                    </Button>
                </div>
            </template>
        </BaseFormCard>

    </Sidebar>

    <ConfirmDialog></ConfirmDialog>
</template>
<script setup lang="ts">
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/Components/ui/form'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/Components/ui/select'
import { Input } from '@/Components/ui/input';
import Button from '@/tiptap/components/ui/button/Button.vue';
import Sidebar from "primevue/sidebar";
import BaseFormCard from "@/Components/forms/BaseFormCard.vue";
import useValidatedForm from "@/hooks/useValidatedFormNew";
import { useToast } from 'primevue/usetoast';
import { useTemplateCategoryType } from '@/hooks/useTemplateCategoryType'
import { ref, watch } from "vue";
import { Switch } from "@/tiptap/components/ui/switch";
import { displayErrorMessagesAsToast } from '@/utils/errorUtils.js';
import { useTemplate } from '@/pages/Templates/useTemplate.js';
import { router } from "@inertiajs/vue3";
import AvatarUpload from "@/Components/forms/AvatarUpload.vue";
import axios from "axios";
import ConfirmDialog from "primevue/confirmdialog";
import Icon from "@/tiptap/components/ui/Icon.vue";
import { useConfirmationModal } from '@/hooks/useConfirmationModal.js';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/Components/ui/tooltip'

const toast = useToast();
const props = defineProps({
    visible: {
        type: Boolean,
        required: true,
    }
})

const showDialog = ref(props.visible);
const {template: model} = useTemplate();
const isSubmitting = ref(false);
watch(() => props.visible, (value) => {
    showDialog.value = value;
    form.vee.setValues(model.value)
});
const emit = defineEmits(['close']);
const loading = ref(false);

const confirm = useConfirmationModal();

const form = useValidatedForm(model.value, {})

const { categories } = useTemplateCategoryType();

function confirmDelete(e) {
    e.stopPropagation();
    confirm({
        accept: async () => {
            console.log('ACCEPTED');

            try {
                await axios.delete(`/templates/${model.value.ulid}`)
                router.visit('/templates')
            } catch (e) {
                displayErrorMessagesAsToast(error);
            }
        },
    })
}

async function submit(e) {
    isSubmitting.value = true;
    const transformedData = {
        _method: 'put',
        name: form.name,
        category: form.category,
        description: form.description,
        published: form.published,
        avatar: form.avatar instanceof File ? form.avatar : undefined,
    };

    const requestData = new FormData();
    Object.keys(transformedData).forEach(key => {
        requestData.append(key, transformedData[key]);
    });

    axios({
        method: 'post',
        url: `/templates/${model.value.ulid}`,
        data: transformedData,
        headers: { 'Content-Type': 'multipart/form-data' },
    }).then(response => {
        router.reload();
        // model.value = response.data.template;
        emit('close', model.value);
    }).catch(error => {
        showDialog.value = false;
        displayErrorMessagesAsToast(error);
    }).finally(() => {
        isSubmitting.value = false;
    });
}

</script>

<style>
data-radix-popper-content-wrapper {
    z-index: 9999 !important;
}
</style>
